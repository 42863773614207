import React, { useState } from 'react';
import { LoadScript } from "@react-google-maps/api";
import ReactDOM from 'react-dom'; 
import * as serviceWorker from './serviceWorker';
import {RideCode} from './component/ride-code/ridecode';
import {Receipt} from './component/receipt/receipt';
import { Helmet } from "react-helmet";

import {
  BrowserRouter as Router,
  Route 
} from "react-router-dom";
import './css/style.css';


export const ThemeContext = React.createContext(process.env.REACT_APP_THEME);
const appFavicon = require(`./img/${process.env.REACT_APP_FAVICON}`);


export function App(){
  const [theme, setTheme] = useState({ value: process.env.REACT_APP_THEME })
  return(
    <ThemeContext.Provider value={theme.value}>
       {(theme.value)?.toLocaleLowerCase() === (process.env.REACT_APP_COOL_RIDE_THEME)?.toLocaleLowerCase() ?
                    <Helmet>
                        <title>{`${process.env.REACT_APP_NAME}`}</title>
                        <link rel='icon' href={appFavicon} />
                        <style>{'body {background-color: black;font-family: Electrolize;color:#DCDCAA;}'}</style>
                    </Helmet>
                    :
                    <Helmet>
                        <title>{`${process.env.REACT_APP_NAME}`}</title>
                        <link rel='icon' href={appFavicon} />
                        <style>{'body {font-family: Dosis, sans-serif; background: #edff02; color: #000;}'}</style>
                    </Helmet>
                }
    <Router>
      <link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/react-draft-wysiwyg@1.12.3/dist/react-draft-wysiwyg.css' />
    <LoadScript
      googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
      libraries={["places", "drawing"]}
      loadingElement={<div style={{display:"none"}}></div>}
    >
    </LoadScript>
      <>
        <Route exact path='/' component={RideCode} />
        <Route exact path='/receipt/:code' component={Receipt} />
        <Route path='/r/:id'  component={Receipt} />
      </>
    </Router>

    </ThemeContext.Provider>
  )
     
  
}

ReactDOM.render(
  <App/>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
